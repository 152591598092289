import React from 'react'
import LearningComponent from './LearningComponent'
import VoiceList from './VoiceList'
import ExerciseComponent from './ExerciseComponent'


class AppComponent extends  React.Component {
    EXERCISE_TYPE = {
        STARTER: 10,
        LONGER: 30
    }

    constructor() {
        super();
        this.state = {
            voicesList: [],
            chosenVoice: null,
            exerciseNumberOfQuestions: this.EXERCISE_TYPE.STARTER
        };
        document.getElementsByClassName("startExerciseButton").autofocus = false;
    }

    componentDidMount () {
        this.loadVoicesList()
    }

    loadVoicesList() {
        console.log("Component Voice List mounted");
        this.bindVoicesToState();
        let componentObject = this;
        window.speechSynthesis.onvoiceschanged = function() {
            if(componentObject.state.voicesList.length === 0) {
                componentObject.bindVoicesToState();
            }
        };
    }

    bindVoicesToState() {
        let voices = window.speechSynthesis.getVoices();
        this.setState({
            voicesList: voices,
            chosenVoice: voices[0]
        })
    }

    handleVoiceChoice = (voiceID) => {
        console.log("Voice was changed to: ", this.state.voicesList[voiceID].name)
        this.setState({
            chosenVoice: this.state.voicesList[voiceID]
        })
    };

    setExerciseType(exerciseNumberOfQuestions) {
        console.log("Exercise number: " + exerciseNumberOfQuestions);
        this.setState({
            exerciseNumberOfQuestions: exerciseNumberOfQuestions
        });
        document.getElementById("exerciseArea").focus();
    };

    speak(text, callback) {
        // window.speechSynthesis.cancel();
        let msg = new SpeechSynthesisUtterance();
        msg.text = text;
        msg.voice = this.state.chosenVoice;
        window.speechSynthesis.speak(msg);
        if(callback !== undefined) {
            msg.addEventListener('end', function () {
                console.log(self);
                console.log('Speak end');
                callback();
            });
        }
    };

    onEnterExerciseHeader = () => {
        console.log("Entered exercise header");
        this.speak("Tryb ćwiczeń, naciśnij przycisk tab i wybierz tryb ćwiczeń. Następnie naciśnij przycisk enter aby rozpocząć.")
    }

    readExerciseButtonDescriptionWithSpeaker = (description) => {
        console.log("Exercise button focused");
        this.speak(description);
    }


    render() {
        var props = [];
        for (var i = 0; i < 8; i++) {
            props[i] = {tabIndex: i + 1};
        }

        return(
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 id="chooseLanguageHeader" {...props[0]}>Wybierz język</h2>
                        <VoiceList tabIndex={props[1]} handleVoiceChoice={this.handleVoiceChoice} voices={this.state.voicesList} />
                        <h2  id="learnHeader" {...props[2]}>Tryb nauki, wyłącz screen reader i naciśnij przycisk tab</h2>
                        <LearningComponent tabIndex={props[3]} speak={this.speak.bind(this)}/>
                        <h2 onFocus={this.onEnterExerciseHeader} {...props[4]} id="exerciseHeader">Tryb ćwiczeń</h2>
                        <button onFocus={() => this.readExerciseButtonDescriptionWithSpeaker("Rozpocznij ćwiczenia - 10 klawiszy")}  onClick={() => {this.setExerciseType(this.EXERCISE_TYPE.STARTER)}}  class="startExerciseButton" {...props[5]} >Rozpocznij ćwiczenia - 10 klawiszy</button>
                        <button onFocus={() => this.readExerciseButtonDescriptionWithSpeaker("Rozpocznij ćwiczenia - 30 klawiszy")}  onClick={() => {this.setExerciseType(this.EXERCISE_TYPE.LONGER)}}  class="startExerciseButton" {...props[6]} >Rozpocznij ćwiczenia - 30 klawiszy</button>
                        <ExerciseComponent exerciseNumber={this.state.exerciseNumberOfQuestions} tabIndex={props[7]} speak={this.speak.bind(this)}/>
                    </div>
                </div>
            </div>
        );
    };
}

export default AppComponent
