/**
 * Created by traffii on 6/17/17.
 */
import React from 'react'

class AbstractTextAreaComponent extends React.Component {
    keyPair  = {
        "8": "backspace",
        "9": "tab",
        "13": "enter",
        "16": "shift",
        "17": "control",
        // "18": "lewy alt",
        "19": "pause/break",
        "20": "caps lock",
        "27": "escape",
        "32": "spacja",
        "33": "page up",
        "34": "page down",
        "35": "end",
        "36": "home",
        "37": "strzałka w lewo",
        "38": "strzałka w górę",
        "39": "strzałka w prawo",
        "40": "strzałka w dół",
        "45": "insert",
        "46": "delete",
        "48": "0",
        "49": "1",
        "50": "2",
        "51": "3",
        "52": "4",
        "53": "5",
        "54": "6",
        "55": "7",
        "56": "8",
        "57": "9",
        "65": "a",
        "66": "b",
        "67": "c",
        "68": "d",
        "69": "e",
        "70": "f",
        "71": "g",
        "72": "h",
        "73": "i",
        "74": "j",
        "75": "k",
        "76": "l",
        "77": "m",
        "78": "n",
        "79": "o",
        "80": "p",
        "81": "q",
        "82": "r",
        "83": "s",
        "84": "t",
        "85": "u",
        "86": "v",
        "87": "w",
        "88": "x",
        "89": "y",
        "90": "z",
        "112": "f1",
        "113": "f2",
        "114": "f3",
        "115": "f4",
        "116": "f5",
        "117": "f6",
        "118": "f7",
        "119": "f8",
        "120": "f9",
        "121": "f10",
        "122": "f11",
        "123": "f12",
        "186": "średnik",
        "187": "znak równości",
        "188": "przecinek",
        "189": "myślnik",
        "190": "kropka",
        "191": "slash",
        "192": "tylda",
        "219": "nawias kwadratowy lewy",
        "220": "backslash",
        "221": "nawias kwadratowy prawy",
        "222": "apostrof",
        // "225": "prawy alt"
    };

    TAB_KEYCODE = 9;
    NUMBER_OF_TAB_TO_EXIT = 3;
    usedKeysArray = [];

    constructor() {
        super()
        if (new.target === AbstractTextAreaComponent) {
            throw new TypeError("Cannot construct Abstract instances directly");
        }
    }

    isExitCombinationOccured() {
        if (this.usedKeysArray.filter(value => value == this.TAB_KEYCODE).length === this.NUMBER_OF_TAB_TO_EXIT) {
            return true;
        }
        return false;
    }

    addKeyToUsedList(keyCode) {
        if (this.usedKeysArray.length === this.NUMBER_OF_TAB_TO_EXIT) {
            this.usedKeysArray.shift();
        }
        console.log(this.usedKeysArray);
        this.usedKeysArray.push(keyCode);

    }
}

export default AbstractTextAreaComponent